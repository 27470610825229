<template>
    <v-text-field
      v-model = "input"
      :label = "obj.schema.label"
      :outlined = "!obj.schema.disabled"
      :dense = "true"
      :rules = "obj.schema.rules"
      :disabled = "obj.schema.disabled"
      class = "mt-2"
    ></v-text-field>
</template>
<script>
export default {
  name:'customTextInput',
  props: ['type','value', 'obj'],
  computed:{
    input:{
      get(){  return this.value},
      set(val){ this.$emit('input', val)}  // listen to @input="handler"
    }
  }
}
</script>
